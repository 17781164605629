export default `
Custom made software development is one of the strongest areas for Computatus.

As the reference Computatus LLC provided the Billing/Charging/CRM system of an IoT MVNO company in the USA, named IOINTEL. IOINTEL made the choice comparing the risks, expertise and investment amounts with respect to the bigger companies. They were aware that the software is implemented by human beings not the company brands and chose the correct people for their BSS infrastructure development. The system is named “MVNO Management System” and provides following functionalities:

Our flexible development teams have the experience in following technologies:

Database Layer (Oracle, MySQL, Redis)

Application Layer (Node.js, Express.js)

Presentation Layer (React, React-Native)


`