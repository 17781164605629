import React, { useRef } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import HeaderView from './HeaderView';
import Consultancy from './Consultancy';
import Solutions from './Solutions';
import Assets from './Assets';
import Insight from './Insight';
import Footer from './Footer';
import Banner from './Banner';

const LandingPage = () => {
	//Scroll
	const scrollViewRef = useRef(null);

	const scrollToInsight = () => {
		scrollViewRef.current.scrollTo({ y: 550, animated: true });
	};

	const scrollToConsultancy = () => {
		scrollViewRef.current.scrollTo({ y: 1200, animated: true });
	};

	const scrollToSoftware = () => {
		scrollViewRef.current.scrollTo({ y: 2200, animated: true });
	};
	const scrollToAssets = () => {
		scrollViewRef.current.scrollToEnd({ animated: true });
	};

	return (
		<>
			<HeaderView
				scrollToSoftware={scrollToSoftware}
				scrollToConsultancy={scrollToConsultancy}
				scrollToInsight={scrollToInsight}
				scrollToAssets={scrollToAssets}
			/>
			<ScrollView style={styles.flex} ref={scrollViewRef} showsVerticalScrollIndicator={false}>
				<Banner onPress={scrollToInsight} />
				<Insight />
				<Consultancy />
				<Solutions />
				<Assets />
				<Footer />
			</ScrollView>

		</>

	);
};

export default LandingPage;

const styles = StyleSheet.create({
	flex: {
		flex: 1,
	},
});
