import { StyleSheet, Text, View, TouchableOpacity, Image } from 'react-native'
import React from 'react'
import { useSelector } from 'react-redux';

const Banner = (props) => {
	const uiTexts = require('../business-logic/ui-texts-english.json');
	const { layout } = useSelector(state => state.layoutSlice);
	const banner = layout === 'landscape' ? styles.container : styles.portraitContainer
	const titleStyle = layout === 'landscape' ? styles.landscapeTitle : styles.portraitTitle
	const descriptionStyle = layout === 'landscape' ? styles.landscapeDescription : styles.portraitDescription
	const InsightButton = layout === 'portrait' ? <></> : <TouchableOpacity onPress={props.onPress} style={styles.insightButton}>
		<Text style={styles.buttonText}>{uiTexts.header.Insight}</Text>
	</TouchableOpacity>;
	return (
		<View style={banner}>
			<Image
				source={require('../assets/images/png/HeaderImage.png')}
				style={styles.images}
			/>
			<View style={styles.firstBanner}>
				<Text style={titleStyle}>{uiTexts.CompanyTitle}</Text>
				<Text style={descriptionStyle}>{uiTexts.ItService}</Text>
				{InsightButton}
			</View>
		</View>
	)
}

export default Banner

const styles = StyleSheet.create({
	flex: {
		flex: 1,
	},
	images: {
		width: '45%',
		aspectRatio: 1,
	},
	firstBanner: {
		flexDirection: 'column',
		flex: 1,
		marginLeft: 20
	},
	insightButton: {
		padding: 10,
		width: '25%',
		backgroundColor: 'white',
		borderRadius: 6,
		alignSelf: 'flex-start',
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonText: {
		color: '#1915BD',
		fontSize: 16,
		textAlign: 'center',
		fontWeight: '500'
	},
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		flex: 1,
		padding: 70
	},
	portraitContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		flex: 1
	},
	landscapeTitle: {
		fontSize: 45,
		color: 'white',
		fontWeight: '600',
		marginBottom: 10,
	},
	portraitTitle: {
		fontSize: 26,
		color: 'white',
		fontWeight: '600',
		marginBottom: 10
	},
	landscapeDescription: {
		fontSize: 35,
		color: 'white',
		fontWeight: '400',
		marginBottom: 20
	},
	portraitDescription: {
		fontSize: 14,
		color: 'white',
		fontWeight: '600',
		marginBottom: 10
	},
});
