import { Image, StyleSheet, Text, View, useWindowDimensions } from 'react-native'
import React from 'react'
import insightEn from '../business-logic/english/insightEn'
import { useSelector } from 'react-redux'

const Insight = () => {
  const windowDimensions = useWindowDimensions();
  const { layout } = useSelector(state => state.layoutSlice);
  const uiTexts = require('../business-logic/ui-texts-english.json').header;

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <View style={styles.textContainer}>
          <Text style={styles.title}>{uiTexts.Insight}</Text>
          <Text style={styles.text}>
            {insightEn}
          </Text>
        </View>
        {layout !== 'portrait' ?
          <View style={styles.imageContainer}>
            <Image
              source={require('../assets/images/png/InsightImage.png')}
              style={{
                width: windowDimensions.width * 0.5, 
                height: windowDimensions.width * 0.5,
                resizeMode: 'contain',
              }}
            />
          </View>
          : null
        }
      </View>
    </View>
  );
}

export default Insight

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#020D22',
    opacity: 0.8,
    width: '98%',
    alignSelf: 'center',
    borderRadius: 12,
    marginTop: 50,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  textContainer: {
    flexDirection: 'column',
    flex: 1,
    alignSelf: 'center',
    padding: 16,
  },
  imageContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: 'white',
    fontSize: 24,
    fontWeight:'500',
    textAlign: 'center',
    marginBottom: 10,
    textTransform:'uppercase'
  },
  text: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
  },
});