import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { Linking } from 'react-native';
import config from '../business-logic/config';

const Footer = () => {
	const uiTexts = require('../business-logic/ui-texts-english.json');

	return (
		<View style={styles.container}>
			<Text style={styles.text}>{uiTexts.footer.fileNumber}</Text>
			<Text style={styles.text}>{uiTexts.footer.registeredAddress}</Text>
			<Text style={styles.text}>{uiTexts.footer.operationCenter}</Text>
			<Text style={styles.text}>{uiTexts.footer.phone}</Text>
			<Text style={styles.text} onPress={() => Linking.openURL(`mailto:${config.emailAddress}`)}>
				{`${config.emailAddress}`}
			</Text>
			<Text style={styles.text}>{uiTexts.footer.copyright.replace("{year}", new Date().getFullYear())}</Text>
		</View>
	)
}

export default Footer

const styles = StyleSheet.create({
	container: {
		backgroundColor: '#020D22',
		width: '100%',
		paddingVertical: 10
	},
	text: {
		color: 'white',
		textAlign: 'center'
	}
})