
// in order to avoid robots to scrap the email address, we split it in two parts:
const emailParts = {
	part1: 'corp-contact',
	part2: '@computatus.com'
}

const config = {
	emailAddress: `${emailParts.part1}${emailParts.part2}`,
}

export default config;