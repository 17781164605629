import React from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import assetEn from '../business-logic/english/assetEn'
const Assets = () => {
   const uiTexts = require('../business-logic/ui-texts-english.json').header;

   return (
      <View style={styles.container}>
         <View style={styles.card}>
            <View style={styles.textContainer}>
               <Text style={styles.title}>{uiTexts.Digital}</Text>
               <Text style={styles.description}>{assetEn}</Text>
            </View>
         </View>
      </View>

   );
};

const styles = StyleSheet.create({
   container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical:50
   },
   card: {
      backgroundColor: '#3A3A3A',
      opacity: 0.8,
      width: '98%',
      alignSelf: 'center',
      borderRadius: 12,
      borderWidth: 2,
      borderColor: 'white',
      padding: 20,
   },
   textContainer: {
      flexDirection: 'column',
      width: '100%',
   },
   title: {
      color: 'white',
      fontSize: 24,
      textAlign: 'center',
      marginTop: 10, 
      textTransform:'uppercase'
   },
   description: {
      fontSize: 14,
      color: 'white',
      textAlign: 'center',
      marginTop: 10,
   },
});

export default Assets;
