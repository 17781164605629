import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';

import { Provider, useDispatch } from 'react-redux';

import ReactNativeAnimatedLayout from 'react-native-animated-layout';

import store from './business-logic/store/store.js';
import { changeStateOfWidthSize } from './business-logic/store/widthSizeSlice';
import { changeStateOfHeightSize } from './business-logic/store/heightSizeSlice';
import BackgroundView from './components/BackgroundView.js';
import LandingPage from './components/landing-page.js';

function App_() {
	const [rerender, setRerender] = useState(0);
	const { height, width } = useWindowDimensions();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(changeStateOfWidthSize(width));
		dispatch(changeStateOfHeightSize(height));
	}, [width, height]);

	const layouts = [
		{
			// portrait
			validAfterWHRatio: 0,
			validBeforeWHRatio: 0.85,
			views: [
				{ top: 0, bottom: 1, left: 0, right: 1, children: <BackgroundView layout={"portrait"} /> },
				{ top: 0, bottom: 1, left: 0, right: 1, children: <LandingPage layout={"portrait"} /> },
			],
		},
		{
			// SQUARELIKE - Kare 
			validAfterWHRatio: 0.85,
			validBeforeWHRatio: 1.2,
			views: [
				{ top: 0, bottom: 1, left: 0, right: 1, children: <BackgroundView layout={"squarelike"} /> },
				{ top: 0, bottom: 1, left: 0, right: 1, children: <LandingPage layout={"squarelike"} /> },
			]
		},
		{
			// landscape
			defaultFlag: true,
			views: [
				{ top: 0, bottom: 1, left: 0, right: 1, children: <BackgroundView layout={"landscape"} /> },
				{ top: 0, bottom: 1, left: 0, right: 1, children: <LandingPage layout={"landscape"} /> },
			],
		},
	];

	return <ReactNativeAnimatedLayout layouts={layouts} rerender={rerender} />

}

export default function App() {
	return <Provider store={store}>
		<App_ />
	</Provider>
}
