import { View,Image } from 'react-native';
import genericStyles from './styles/generic-styles';
import { useEffect } from 'react';
import { setLayout } from '../business-logic/store/layoutSlice';
import { useDispatch } from 'react-redux';
export default function BackgroundView(props) {

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setLayout(props.layout))
	}, [props.layout])

	return <View style={genericStyles.backgroundView}>
		<Image style={{ width: '100%', height: '100%' }} source={require('../assets/background.png')} resizeMode='cover' />
	</View>
}